<template>
  <div
    class="game-params-root"
  >
    <div
      class="game-params-content"
    >
      <div
        class="global-params-items"
      >
        <div
          v-for="param in globalParams"
          :key="param.key"
          class="global-param-single"
          :class="{
            'auto-width': param.type === 'boolean' || param.type === 'text-toggle',
            'has-divider': param.hasDivider,
          }"
        >
          <div
            v-if="param.hasDivider"
            class="divider"
          />
          <div
            v-if="param.type !== 'boolean'"
            class="global-param-single-header"
          >
            <div class="short-name">
              {{ param.shortName }}
            </div>
            <Tooltip
              :text="param.longName"
              right
            >
              <Icon
                class="global-param-single-name-icon"
                name="info"
              />
            </Tooltip>
          </div>
          <NumberInput
            v-if="!param.type"
            class="global-param-single-input"
            :class="{ 'is-error': param.isError }"
            :model-value="param.value"
            :is-error="param.isError"
            :error-message="param.errorMessage"
            :accept-negative="(param.minValue < 0)"
            :number-of-decimals="param.numberOfDecimals || 3"
            :readonly="isInferActive || autoMode"
            :has-arrows="true"
            @update:modelValue="onGlobalParamChange($event, param)"
            @onKeyUp="onGlobalParamIncrease(param)"
            @onKeyDown="onGlobalParamDecrease(param)"
          />
          <div
            v-if="param.type === 'boolean'"
            class="global-param-header-toggle"
          >
            <Toggle
              :model-value="param.value"
              :disabled="autoMode"
              @update:model-value="value => { onGlobalParamChange(value, param) }"
            />
            <span>{{ param.longName }}</span>
          </div>
          <TextToggle
            v-if="param.type === 'text-toggle'"
            :model-value="param.value"
            :disabled="autoMode"
            :toggle-items="param.options"
            @update:model-value="value => { onGlobalParamChange(value, param) }"
          />
        </div>
      </div>
      <div
        class="team-parameters-root"
      >
        <div class="team-parameters-header">
          <div class="title">
            Team parameters
          </div>
          <Button
            v-if="!hideShowAllTeamParamsToggle"
            :icon="showAllTeamParams ? 'chevron-up' : 'chevron-down'"
            @click="toggleShowAllTeamParams"
          >
            {{ showAllTeamParams ? 'Hide all team params' : 'Show all team params' }}
          </Button>
        </div>
        <div class="team-parameters-content">
          <TeamParams
            :is-show-all-team-parameters-active="showAllTeamParams"
            :team-label="teamASide"
            :team-name="teamAName"
            :auto-mode="autoMode"
          />
          <TeamParams
            :is-show-all-team-parameters-active="showAllTeamParams"
            :team-label="teamBSide"
            :team-name="teamBName"
            :auto-mode="autoMode"
          />
        </div>
      </div>
      <ParamsSetupMarkets
        :event-id="eventId"
        :auto-mode="autoMode"
        :competition-type="eventData?.competitionType"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { cloneDeep, filter, includes } from 'lodash';
import NumberInput from '@/components/common/NumberInput';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import Toggle from '@/components/common/Toggle';
import TextToggle from '@/components/common/TextToggle';
import { getGlobalParamsKeysBySport, getBasketballGlobalParamsModelType } from './params-helper';
import TeamParams from './setup-by-team/TeamParams';
import ParamsSetupMarkets from './ParamsSetupMarkets';
import sportIds from '@/services/helpers/sports';

const {
  HOCKEY_ID,
  FOOTBALL_ID,
} = sportIds;

export default {
  emits: ['toggleShowAllTeamParameters'],
  components: {
    NumberInput,
    Tooltip,
    Icon,
    Button,
    TeamParams,
    ParamsSetupMarkets,
    Toggle,
    TextToggle,
  },
  props: {
    sportId: {
      type: String,
      default: '',
    },
    autoMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const matchState = computed(() => store.getters.playerSetupData?.matchState);
    const storeGameParams = computed(() => store.getters.playerSetupGameParams);
    const globalParamsModelType = computed(() => getBasketballGlobalParamsModelType(store.getters.playerSetupData?.competitionType));
    const globalParamsKeys = computed(() => getGlobalParamsKeysBySport(props.sportId, globalParamsModelType.value));
    const globalParams = computed(() => filter(
      storeGameParams.value, (param) => includes(globalParamsKeys.value, param.key)
      && (includes(param.availableInMatchStates, matchState.value) || !param.availableInMatchStates),
    ));
    const hideShowAllTeamParamsToggle = computed(() => props.sportId === FOOTBALL_ID);
    const showAllTeamParams = ref(props.sportId === HOCKEY_ID || props.sportId === FOOTBALL_ID);
    const eventData = computed(() => store.getters.playerSetupData || {});
    const eventId = computed(() => eventData.value?.eventId);
    const teamAName = computed(() => eventData.value.teamA?.name);
    const teamASide = computed(() => eventData.value.teamA?.label);
    const teamBName = computed(() => eventData.value.teamB?.name);
    const teamBSide = computed(() => eventData.value.teamB?.label);
    const isInferActive = computed(() => store.getters.playerSetupInferActive);
    const onGlobalParamChange = (value, param) => {
      const updatedParam = cloneDeep(param);
      if (param.type === 'boolean' || param.type === 'text-toggle') {
        if (param.type === 'text-toggle' && param.value === value) {
          updatedParam.value = 'NEUTRAL';
        } else {
          updatedParam.value = value;
        }
        store.dispatch('updatePlayerSetupGameParams', updatedParam);
        return;
      }
      if (value) {
        const paramValue = parseFloat(value);
        const isError = paramValue < param?.minValue || paramValue > param?.maxValue;
        updatedParam.value = paramValue;
        updatedParam.isError = isError;
      } else {
        updatedParam.isError = true;
        updatedParam.value = null;
      }
      store.dispatch('updatePlayerSetupGameParams', updatedParam);
    };

    const onGlobalParamIncrease = (param) => {
      if (param.isError) return;
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value + updatedParam.raiseIncrement).toFixed(param.numberOfDecimals || 3));
      if (updatedValue <= updatedParam.maxValue) {
        updatedParam.value = updatedValue;
        store.dispatch('updatePlayerSetupGameParams', updatedParam);
      }
    };

    const onGlobalParamDecrease = (param) => {
      if (param.isError) return;
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value - updatedParam.raiseIncrement).toFixed(param.numberOfDecimals || 3));
      if (updatedValue >= updatedParam.minValue) {
        updatedParam.value = updatedValue;
        store.dispatch('updatePlayerSetupGameParams', updatedParam);
      }
    };

    const toggleShowAllTeamParams = () => {
      showAllTeamParams.value = !showAllTeamParams.value;
    };

    return {
      globalParams,
      onGlobalParamChange,
      onGlobalParamIncrease,
      onGlobalParamDecrease,
      toggleShowAllTeamParams,
      showAllTeamParams,
      teamAName,
      teamBName,
      teamASide,
      teamBSide,
      eventId,
      isInferActive,
      hideShowAllTeamParamsToggle,
      eventData,
    };
  },
};
</script>

<style lang="scss">
.game-params-root {
  padding-top: 16px;
  .game-params-content {
    width: 100%;
    .global-params-items {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $gray400;
      padding: 16px;
      .global-param-single {
        width: 67px;
        &.auto-width {
          width: auto;
        }
        &.is-error {
          width: 90px;
        }
        &.has-divider {
          width: calc(67px + 38px);
          padding-left: 38px;
          position: relative;
        }
        .divider {
          width: 1px;
          height: 100%;
          background-color: $gray400;
          margin: 0 27px 0 11px;
          position: absolute;
          left: 0;
          top: 0;
        }
        .global-param-single-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .short-name {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $gray800;
            text-transform: uppercase;
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 2px;
            font-weight: 600;
          }
          .global-param-single-name-icon {
            cursor: pointer;
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
        .global-param-single-input {
          width: 100%;
          input {
            padding: 5px 8px;
          }
        }
        .global-param-header-toggle {
          display: flex;
          align-items: center;
          gap: 4px;
          margin-left: 16px;
        }
      }
    }
    .team-parameters-root {
      .team-parameters-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        margin-top: 16px;
        padding: 0 16px;
        .title {
          font-size: 16px;
          line-height: 19px;
          font-weight: 600;
        }
        .button .icon svg path {
          stroke: $black;
        }
      }
      .team-parameters-content {
        display: flex;
        .team-params-root {
          width: 50%;
        }
      }
    }
  }
}
</style>
